<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2024 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-card class="entity-occurrences" elevation="2">
    <v-card-title class="text-subtitle-1 font-weight-medium">
      <v-spacer></v-spacer>
      <v-chip small text-color="white">
        {{ entityOccurrences.length }} occurrences
      </v-chip>
    </v-card-title>

    <v-sheet color="transparent" elevation="0" rounded="lg" class="pa-2">
      <v-sparkline
        :value="value"
        :gradient="gradient"
        :line-width="2"
        :padding="16"
        :smooth="10"
        auto-draw
        stroke-linecap="round"
        type="bar"
        height="60"
        :auto-draw-duration="1500"
      ></v-sparkline>
    </v-sheet>

    <v-card-text class="pt-0">
      <div class="text-caption grey--text">
        <v-icon x-small class="mr-1">mdi-calendar-range</v-icon>
        {{ formatDate(entity.created_at) }} &mdash;
        <span v-if="lastOccurrence">{{ formatDate(lastOccurrence.occurred_at) }}</span>
        <span v-else>UNKN</span>
      </div>

      <v-data-table
        :headers="headers"
        :items="paginatedOccurrences"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        class="mt-2 mb-2 elevation-1 occurrence-table"
        @click:row="handleRowClick"
      >
        <template v-slot:item.occurred_at="{ item }">
          {{ formatDate(item.occurred_at) }}
        </template>
        <template v-slot:item.description="{ item }">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">{{ truncate(item.description, 50) }}...</span>
            </template>
            <span>{{ item.description }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.action>
          <v-icon small class="chat-hint">mdi-chat-processing-outline</v-icon>
        </template>
      </v-data-table>

      <div class="text-center pt-2">
        <v-pagination
          v-model="page"
          :length="pageCount"
          :total-visible="7"
        ></v-pagination>
      </div>

      <v-divider class="my-2"></v-divider>

      <v-chip
        outlined
        small
        class="mt-1"
      >
        <v-icon left x-small>mdi-clock-outline</v-icon>
        <span v-if="lastOccurrence && lastOccurrence.occurred_at" class="text-caption">
          Last: {{ lastOccurrence.occurred_at | formatRelativeDate }}
          ({{ lastOccurrenceType }})
        </span>
        <span v-else class="text-caption">UNKN</span>
      </v-chip>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapActions } from 'vuex';
import { VSnackbar } from 'vuetify/lib';

export default {
  name: "EntityOccurrences",

  components: {
    VSnackbar,
  },

  data() {
    return {
      labels: [],
      value: [],
      gradient: ['#0D47A1', '#00BCD4', '#CDDC39'],
      headers: [
        { text: 'Type', value: 'occurrence_type' },
        { text: 'Date', value: 'occurred_at' },
        { text: 'Description', value: 'description' },
        { text: '', value: 'action', sortable: false, width: '48px' },
      ],
      page: 1,
      itemsPerPage: 5,
    };
  },

  computed: {
    ...mapFields("entity", ["selected.occurrences", "selected.loading"]),
    lastOccurrence() {
      return this.entityOccurrences[0];
    },
    lastOccurrenceType() {
      return this.lastOccurrence ? this.lastOccurrence.occurrence_type : "UNKN";
    },
    pageCount() {
      return Math.ceil(this.entityOccurrences.length / this.itemsPerPage);
    },
    paginatedOccurrences() {
      const start = (this.page - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.entityOccurrences.slice(start, end);
    },
  },

  props: {
    entityOccurrences: {
      type: Array,
      required: true,
      default: () => [],
    },
    entity: {
      type: Object,
      required: true,
    },
    chartColor: {
      type: String,
      default: "primary",
    },
  },

  mounted() {
    this.processOccurrences();
  },

  methods: {
    ...mapActions('websocket', ['setActiveObject']),
    handleRowClick(item) {
      this.setActiveObject({
        type: item.occurrence_type,
        id: item.occurrence_bao_id,
      });

      // Use nextTick to ensure DOM is updated before scrolling
      this.$nextTick(() => {
        const chatContainer = document.querySelector('.chat-container');
        if (chatContainer) {
          chatContainer.scrollTop = chatContainer.scrollHeight;
        }
      });
    },
    processOccurrences() {
      const occurrencesByHour = this.entityOccurrences.reduce((acc, occurrence) => {
        const date = new Date(occurrence.occurred_at);
        const hour = date.getHours();
        const formattedHour = `${hour < 10 ? '0' : ''}${hour}`;
        acc[formattedHour] = (acc[formattedHour] || 0) + 1;
        return acc;
      }, {});

      this.labels = Object.keys(occurrencesByHour);
      this.value = Object.values(occurrencesByHour);

      if (this.value.length < 2) {
        this.labels = ['00', '01'];
        this.value = [1, 0];
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });
    },
    truncate(text, length) {
      return text.length > length ? text.substring(0, length) : text;
    },
  },
};
</script>

<style scoped>
.entity-occurrences {
  backdrop-filter: blur(10px);
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 8px 32px rgba(0, 198, 255, 0.1);
  transition: all 0.3s ease;
}

.entity-occurrences:hover {
  box-shadow: 0 12px 48px rgba(0, 198, 255, 0.2);
}

.v-card-title {
  background: rgba(0, 198, 255, 0.1);
  color: #004bfb;
}

.v-sparkline {
  margin-top: 10px;
}

.v-data-table {
  background: transparent !important;
}

.occurrence-table ::v-deep tbody tr {
  transition: all 0.3s ease;
  cursor: pointer;
}

.occurrence-table ::v-deep tbody tr:hover {
  background-color: rgba(0, 198, 255, 0.1) !important;
  box-shadow: 0 0 15px rgba(0, 198, 255, 0.3);
}

.chat-hint {
  opacity: 0;
  transition: opacity 0.3s ease;
  color: #004bfb !important;
}

.occurrence-table ::v-deep tbody tr:hover .chat-hint {
  opacity: 1;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

.occurrence-table ::v-deep tbody tr:hover .chat-hint {
  animation: pulse 1.5s infinite;
}

.v-pagination ::v-deep .v-pagination__item {
  background: rgba(0, 198, 255, 0.1) !important;
  color: #004bfb !important;
}

.v-pagination ::v-deep .v-pagination__item--active {
  background: rgba(0, 198, 255, 0.3) !important;
}

.v-divider {
  border-color: rgba(0, 198, 255, 0.2) !important;
}

/* Modern, fixed-width tooltip style */
.v-tooltip__content {
  border-radius: 8px !important;
  font-size: 12px !important;
  max-width: 300px !important;
  padding: 8px 12px !important;
}
</style>
