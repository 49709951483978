<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2024 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <div>
    <!-- Search Input -->
    <v-text-field
      v-model="search"
      label="Search"
      class="mb-4"
    ></v-text-field>

    <v-data-table
      :headers="headers"
      :items="filteredOccurrences"
      :items-per-page="10"
      @click:row="handleRowClick"
      :footer-props="{
      'items-per-page-options': [25, 50, 100],
      }"
      class="occurrence-table"
    >
      <template v-slot:item.occurred_at="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{ item.occurred_at | formatRelativeDate }}</span>
          </template>
          <span>{{ item.occurred_at | formatDate }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.description="{ item }">
        <entity-context :occurrence_context="item.description" />
      </template>
      <template v-slot:item.entity_occurrence_raw="{ item }">
        <entity-occurrence-raw-dialog :entityOccurrenceRaw="item.entity_occurrence_raw" />
      </template>
      <template v-slot:item.action>
        <v-icon small class="chat-hint">mdi-chat-processing-outline</v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import EntityOccurrenceRawDialog from "@/entity/EntityOccurrenceRawDialog.vue";
import EntityContext from "@/entity/EntityContext.vue";
import { mapActions } from "vuex";

export default {
  name: "EntityOccurrenceTable",
  components: {
    EntityOccurrenceRawDialog,
    EntityContext,
  },
  props: {
    entityOccurrences: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      search: "", // Search query
      headers: [
        { text: "Object ID", value: "occurrence_bao_id", sortable: true },
        { text: "Occurrence Type", value: "occurrence_type", sortable: true },
        { text: "Occurrence Date", value: "occurred_at", sortable: true },
        { text: "Occurrence ID (EOID)", value: "id", sortable: true },
        { text: "Occurrence Context", value: "description", sortable: false },
        { text: "Occurrence Raw", value: "entity_occurrence_raw", sortable: false },
        { text: "", value: "action", sortable: false, width: "48px" },
      ],
    };
  },
  methods: {
    ...mapActions('websocket', ['setActiveObject']),
    handleRowClick(item) {
      this.setActiveObject({
        type: item.occurrence_type,
        id: item.occurrence_bao_id,
      });
      this.$emit('close-dialog');
    },
  },
  computed: {
    filteredOccurrences() {
      const searchLower = this.search.toLowerCase();
      return this.entityOccurrences.filter(item =>
        Object.keys(item).some(key =>
          String(item[key]).toLowerCase().includes(searchLower)
        )
      );
    },
  },
};
</script>

<style scoped>
.occurrence-table ::v-deep tbody tr {
  position: relative;
  transition: all 0.3s ease;
  cursor: pointer;
}

.occurrence-table ::v-deep tbody tr:hover {
  box-shadow: 0 0 15px rgba(3, 169, 244, 0.5);
}

.chat-hint {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.occurrence-table ::v-deep tbody tr:hover .chat-hint {
  opacity: 1;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.occurrence-table ::v-deep tbody tr:hover .chat-hint {
  animation: pulse 1.5s infinite;
}
</style>
