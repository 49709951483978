<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
    <div>
      <v-menu v-model="menu" bottom right transition="scale-transition" origin="top left">
        <template v-slot:activator="{ on }">
          <v-chip pill small v-on="on" class="ma-1">
            <v-avatar color="secondary" left>
              <v-icon small class="white--text">mdi-tag-outline</v-icon>
            </v-avatar>
            <div v-if="entity.name">
              {{ truncateText(entity.name) }}
            </div>
            <div v-else>
              UNKN
            </div>
          </v-chip>
        </template>
        <v-card width="800">
          <v-list dark>
            <v-list-item>
              <v-list-item-avatar color="secondary">
                <v-icon class="white--text">mdi-tag-multiple</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-if="entity.entity_type">
                  {{ entity.entity_type.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list>
            <v-list-item>
              <v-list-item-action>
                <v-icon>mdi-equal</v-icon>
              </v-list-item-action>
              <v-list-item-subtitle>{{ entity.value }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-icon>mdi-text</v-icon>
              </v-list-item-action>
              <v-list-item-subtitle>{{ entity.description }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-icon>mdi-counter</v-icon>
              </v-list-item-action>
              <v-list-item-subtitle>{{ entity.count }}</v-list-item-subtitle>
            </v-list-item>
          </v-list>
          <v-list-item>
            <entity-occurrences
              :entityOccurrences="entity.occurrences"
              :entity="entity"
            />
          </v-list-item>
          <v-list-item>
            <v-btn small
              color="primary"
              @click="occurenceDialog = true"
            >
              View Occurrences
            </v-btn>
          </v-list-item>
        </v-card>
      </v-menu>
      <v-dialog v-model="occurenceDialog" max-width="1080">
          <entity-occurrence-table :entityOccurrences="entity.occurrences" />
      </v-dialog>
    </div>
  </template>

<script>
  import EntityOccurrences from "@/entity/EntityOccurrences.vue";
  import EntityOccurrenceTable from "@/entity/EntityOccurrenceTable.vue";
  export default {
    name: "EntityPopover",
    components: {
      EntityOccurrences,
      EntityOccurrenceTable,
    },
    props: {
      entity: {
        type: Object,
        default: function () {
          return {}
        },
      },
    },
    data: () => ({
      menu: false,
    }),
    methods: {
      truncateText(text) {
        if (text.length > 50) {
          return text.substring(0, 50) + "...";
        }
        return text;
      },
    }
  };
  </script>
