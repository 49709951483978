<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
    <div>
        <v-menu v-model="menu" bottom right transition="scale-transition" origin="top left">
            <template v-slot:activator="{ on }">
                <v-chip pill small v-on="on" class="ma-1">
                    <v-avatar color="secondary" left>
                        <v-icon small class="white--text">mdi-tag-outline</v-icon>
                    </v-avatar>
                    {{ truncateText(occurrence_context) }}
                </v-chip>
            </template>
            <v-card width="700" height="600">
                <v-list dark>
                    <v-list-item>
                        <v-list-item-avatar color="secondary">
                            <v-icon class="white--text">mdi-tag-multiple</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>
                                Entity Context
                            </v-list-item-title>
                        <v-list-item-subtitle>
                            The IAO entity context
                        </v-list-item-subtitle>

                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-list>
                    <v-list-item>
                        <v-list-item-action>
                            <v-icon>mdi-equal</v-icon>
                        </v-list-item-action>
                        <v-list-item-content class="scrollable-content">{{ occurrence_context }}</v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
export default {
    name: "EntityContext",
    props: {
        occurrence_context: {
            type: String,
            default: "",
        },
    },
    data: () => ({
        menu: false,
    }),
    methods: {
        truncateText(text) {
            if (text.length > 10) {
                return text.substring(0, 10) + "...";
            }
            return text;
        },
    },
};
</script>

<style scoped>
.scrollable-content {
    max-height: 400px;
    overflow-y: auto;
}
</style>
