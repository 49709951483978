<template>
  <div>
    <v-dialog max-width="700px" v-model="dialog">
      <template v-slot:activator="{ on, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltipOn }">
            <v-btn v-bind="attrs" v-on="{ ...on, ...tooltipOn }">
              <v-icon color="primary">mdi-creation</v-icon>
            </v-btn>
          </template>
          <span>View Entity Occurrence Raw Extract</span>
        </v-tooltip>
      </template>

      <v-card>
        <v-card-title>
          <v-icon small left>mdi-auto-fix</v-icon>
          Entity Occurrence Raw Extract
        </v-card-title>

        <v-card-text ref="cardText">
          <v-expansion-panels popout>
            <v-expansion-panel
              v-for="(key, index) in visibleKeys"
              :key="key"
              :value="activePanels.includes(index)"
            >
              <v-expansion-panel-header>{{ key }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div>
                  <div v-if="Array.isArray(entityOccurrenceRaw[key])">
                    <ul>
                      <li v-for="(item, itemIndex) in entityOccurrenceRaw[key]" :key="itemIndex">{{ item }}</li>
                    </ul>
                  </div>
                  <div v-else-if="typeof entityOccurrenceRaw[key] === 'object' && entityOccurrenceRaw[key] !== null">
                    <div v-for="(subValue, subKey) in entityOccurrenceRaw[key]" :key="subKey">
                      {{ subKey }}: {{ subValue }}
                    </div>
                  </div>
                  <div v-else>
                    {{ entityOccurrenceRaw[key] }}
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>

        <v-card-actions>
          <v-btn icon @click="onLike">
            <v-icon>mdi-thumb-up-outline</v-icon>
          </v-btn>
          <v-btn icon @click="onDislike">
            <v-icon>mdi-thumb-down-outline</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    entityOccurrenceRaw: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {
      dialog: false,
      activePanels: [],
      visibleKeys: []
    };
  },
  created() {
    this.progressiveDisclosure();
  },
  methods: {
    onLike() {
      this.$emit('like');
      this.dialog = false;
    },
    onDislike() {
      this.$emit('dislike');
      this.dialog = false;
    },
    progressiveDisclosure() {
      const keys = this.sortedKeys;
      const displayNextPanel = (index) => {
        if (index < keys.length) {
          this.visibleKeys.push(keys[index]);
          this.activePanels.push(index); // Expand the current panel
          this.$nextTick(() => {
            this.scrollToBottom();
            setTimeout(() => displayNextPanel(index + 1), 100); // Adjust delay between panels
          });
        }
      };
      displayNextPanel(0);
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const cardTextElement = this.$refs.cardText;
        if (cardTextElement) {
          cardTextElement.scrollTop = cardTextElement.scrollHeight;
        }
      });
    }
  },
  computed: {
    sortedKeys() {
      return Object.keys(this.entityOccurrenceRaw).sort();
    }
  }
}
</script>

<style scoped>
.scrollable-content {
  max-height: 400px;
  overflow-y: auto;
}
</style>
